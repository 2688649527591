import React, { useEffect, useRef, useState } from 'react';
import './WoordWebSlideContent.scss';

/*
     node states:

     READ-ONLY / CENTER NODE : toon tekst, geen interactie
     TEKST + LINE : toon tekst, click om te editen
     EDIT : toon tekst in input veld
     NEW : toon (+)
     HIDDEN : toon helemaal niks
*/

const MAX_NODES = 20;

const NodeState = {
    READONLY: 0,
    VISIBLE: 1,
    EDITING: 2,
    ADD: 3,
    HIDDEN: 4
}

const NODE_POSITIONS = [
    { x: 50, y: 50 },
    { x: 20, y: 30 },
    { x: 80, y: 20 },
    { x: 70, y: 80 },
    { x: 70, y: 36 },
    { x: 30, y: 70 },
    { x: 50, y: 10 },
    { x: 30, y: 90 },
    { x: 50, y: 70 },
    { x: 20, y: 50 },
    { x: 60, y: 40 },
    { x: 80, y: 30 },
    { x: 80, y: 70 },
    { x: 70, y: 50 },
    { x: 60, y: 90 },
    { x: 40, y: 40 },
    { x: 90, y: 90 },
    { x: 40, y: 80 },
    { x: 10, y: 80 },
    { x: 10, y: 10 },
];

function isEmpty(s) {
    return s.replace(/\s/g, "").length == 0;
}

const Node = ({ info, onClick, onChange, onSubmit, index }) => {
    const refInput = useRef();

    useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
        }
    });

    const style = {
        left: `${NODE_POSITIONS[index].x}%`,
        top: `${NODE_POSITIONS[index].y}%`,
    };

    let content;
    switch (info.state) {
        case NodeState.EDITING:
            content = <input ref={refInput} type="text"
                maxLength="80"
                value={info.text}
                onChange={e => onChange(e.target.value)}
                onKeyPress={e => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                        onSubmit();
                    }
                }}
                onBlur={onSubmit}
                style={style}
            />;
            break;
        case NodeState.VISIBLE:
            content = <span onClick={onClick} style={style}>{info.text}</span>
            break;
        case NodeState.READONLY:
            content = <span style={style}>{info.text}</span>;
            break;
        case NodeState.ADD:
            content = <button onClick={onClick} style={style}>+</button>
            break;
        default:
            return null;
    }
    return content;
}

const LineDrawing = ({ nodes }) => {
    const lineStyle = {
        stroke: '#1141e2',    /// $blue
        strokeWidth: 0.15
    };
    return <svg className="line-drawing" viewBox="0 0 100 100" preserveAspectRatio="none">
        {nodes.map((node, index) => {
            const x = (NODE_POSITIONS[index].x - 50) * .9 + 50;
            const y = (NODE_POSITIONS[index].y - 50) * .9 + 50;
            return <line key={index} x1="50" y1="50"
                strokeDasharray="1.5 1"
                x2={x}
                y2={y}
                style={lineStyle} />
        })}
    </svg>
}

const WoordWebSlideContent = ({ slideField }) => {
    // console.log('[WoordWebSlideContent]', slideField);
    const slide = slideField.json;
    const storageKey = `woordweb ${slide.tekst}`;
    const storageSave = _nodes => window.sessionStorage.setItem(storageKey, JSON.stringify(_nodes));
    const storageLoad = () => window.sessionStorage.getItem(storageKey);
    
    const defaultValue = [
        { text: slide.tekst, state: NodeState.READONLY },
        { text: '', state: NodeState.ADD }
    ];
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        let initialValue = storageLoad();
        initialValue = initialValue ? JSON.parse(initialValue) : defaultValue
        setNodes(initialValue);
    }, [storageKey]);

    const setNode = (index, fn) => {
        let newNodes = [...nodes];
        newNodes[index] = fn(newNodes[index]);
        setNodes(newNodes);
    }

    const changeNode = (array, index, fn) => {
        array[index] = fn(array[index]);
    }

    const onChangeNode = (index, value) => {
        setNode(index, el => ({ text: value, state: el.state }));
    }

    const onSubmitNode = (index) => {
        let newNodes = [...nodes];
        changeNode(newNodes, index, el => (
            isEmpty(el.text)
                ? { text: '', state: NodeState.ADD }
                : { text: el.text, state: NodeState.VISIBLE }));

        if (newNodes.length < index + 2 && newNodes.length < MAX_NODES) {
            newNodes.push({ text: '', state: NodeState.ADD });
        }

        setNodes(newNodes);
        storageSave(newNodes);

        slideField.setIsChanged(true);
    }

    slideField.onImport = data => {
        const newNodes = [
            { text: slide.tekst, state: NodeState.READONLY },
            ...data.map(text => ({ text, state: NodeState.VISIBLE })),
            { text: '', state: NodeState.ADD }
        ].slice(0, MAX_NODES);;
        setNodes(newNodes);
        storageSave(newNodes);

        slideField.setIsChanged(false);
    }

    slideField.onExport = () => nodes.slice(1).map(el => el.text);

    const onClickNode = (index) => {
        switch (nodes[index].state) {
            case NodeState.ADD:
            case NodeState.VISIBLE:
                setNode(index, el => ({ text: el.text, state: NodeState.EDITING }));
                break;
        }
    }
    return <div className="woordweb-slide">
        <LineDrawing nodes={nodes} />
        <div className="nodes">
            {nodes.map((el, index) => <Node key={index}
                index={index}
                info={el}
                onClick={() => onClickNode(index)}
                onChange={(value) => onChangeNode(index, value)}
                onSubmit={() => onSubmitNode(index)}
            />)}
        </div>
    </div>
}
export default WoordWebSlideContent;
