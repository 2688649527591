import React, { useEffect } from 'react';
import LogoWit from '../../../assets/ui/logo_maakkunde_wit.svg';

import './FrontPage.scss';

const NO_LESSON_ID = 4;

function hasValue(value) {
    return value !== null, value !== "" && typeof (value) !== "undefined";
}

const Frontpage = ({ json, mode }) => {
    // console.log(json, mode);

    const isEndPage = mode === "end";

    if (!isEndPage) {
        useEffect(() => {
            window.sessionStorage.clear();
        }, []);
    }

    const startlink = "#0/0";
    const nextLink = `?lesson=${json.volgende_les.les}`;
    const stopLink = json.volgende_les.stop_url || '/';
    //const hasNextLink = !!(json.volgende_les.les);
    // const hasNextLink = !!(json.volgende_les.les) && hasValue(json.volgende_les?.knoptekst);
    // hoe detecteren we dat er geen volgende les is? dan is de les dropdown leeg of verwijst naar les 4 (develop les)
    // dit is een workaround omdat ik nu het CMS even niet kan updaten
    const hasNextLink = !!(json.volgende_les.les) && json.volgende_les.les !== NO_LESSON_ID;

    // function downloadClicked() {
    //     window.alert('Tja, wat downloaden we hier dan?');
    // }

    return <div className="Frontpage">
        <div id="banner-bar">
            {hasValue(json.banner) && hasValue(json.banner.path) && <img id="banner" src={`${__BACKEND__.files}${json.banner.path}`} />}
            <img id="main-logo" src={LogoWit} />
        </div>
        {hasValue(json.afbeelding) && hasValue(json.afbeelding.path) && <img id="subject-logo" src={`${__BACKEND__.files}${json.afbeelding.path}`} />}
        <div id="content-block">
            {isEndPage
                ? <h1>Klaar!</h1>
                : <h1>{json.titel}<div id="niveau"><span>{json.niveau}</span></div></h1> }
            {isEndPage
                ? <h2>Dat was de les over {json.titel}. Wat willen jullie nu doen?</h2>
                : <h2>{json.subtitel}</h2>}
            <div id="button-bar">
                {!isEndPage && <a href={startlink} className="button primary left-icon right-arrow">Start</a>}
                {/* {isEndPage && <button onClick={downloadClicked} className="secondary">Onze woorden downloaden</button>} */}
                {isEndPage && <a href={stopLink} className="button secondary">Stoppen</a>}
                {hasNextLink && isEndPage && <a href={nextLink} className="button primary left-icon right-arrow">{json.volgende_les?.knoptekst || "Volgende Les"}</a>}
            </div>
        </div>
    </div>
}
export default Frontpage;
