import React, { useState } from "react";
import EnterIcon from '../../assets/ui/iconFullscreen.svg';
import ExitIcon from '../../assets/ui/iconExitFullscreen.svg';

function multiBrowserCall(target, methodNames) {
    for (let name of methodNames) {
        if (target[name]) {
            target[name]();
            console.log('multiBrowserCall succeeded with', name);
            return;
        }
    }
    console.log('multiBrowserCall failed for', target, 'with', methodNames);
}

function enterFullscreen() {
    const elem = window.document.documentElement;
    multiBrowserCall(elem, [
        'requestFullscreen',
        'webkitRequestFullScreen',
        'mozRequestFullScreen',
        'msRequestFullscreen',
    ]);
}

function exitFullscreen() {
    const doc = window.document;
    multiBrowserCall(doc, [
        'exitFullscreen',
        'webkitExitFullscreen',
        'mozCancelFullScreen',
        'msExitFullscreen',
    ]);
}

const FullScreenButton = ({asButton}) => {
    const [full, setFull] = useState(false)
    const toggle = () => {
        if (full) exitFullscreen()
        else enterFullscreen()
        setFull(!full)
    }    
    return asButton
        ? <button className="button square" onClick={toggle}>
            <img id="fullscreenButton" src={full ? ExitIcon : EnterIcon} alt="Volledig scherm" />
        </button>
        :   <img id="fullscreenButton" onClick={toggle} src={full ? ExitIcon : EnterIcon} alt="Volledig scherm" />
}
export default FullScreenButton
