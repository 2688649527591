import { request } from "./request";

/*
    Returns:
    {
        id,
        titel,
        subtitel
        code,
        afbeelding,
        chapters: [
            {
                titel,
                icoon,
                slides: [
                    { id: number },
                    ...
                ]
            },
            ...
        ],
        volgende_les: {
            les: id,
            knoptekst
        }
    }
*/
function transformLesson(lesson) {
    let { titel, subtitel, code, afbeelding, niveau, banner, volgende_les } = lesson.fieldValues;
    let result = { id: lesson.id, titel, subtitel, code, afbeelding, niveau, banner, volgende_les };

    let chapters = []
    let current = null
    if (lesson.fieldValues.inhoud) {
        for (let el of lesson.fieldValues.inhoud) {
            if (el.name === "hoofdstuk") {
                if (current) {
                    chapters.push(current);
                }
                current = {
                    titel: el.value.titel,
                    nummering: el.value.nummering,      // enum [ Intro, Lamp, Schaar, Potlood, Vergrootglas, Vraagteken ]
                    slides: []
                };
            } else if (current) {
                current.slides.push({
                    id: el.value
                });
            } else {
                console.error("slide zonder hoofdstuk", el);
            }
        }
        if (current) {
            chapters.push(current);
        }
    }
    result.chapters = chapters;

    return result;
}

/*
    Returns:
    {
        success: boolean,
        json: [
            transformLession(),
            transformLesson(),
            ...
        ]
    }
*/
export async function getLessons() {
    const result = await request(null, `${__BACKEND__.api}contents?contentType=lessen&pageSize=10000`);

    const transformed = {
        success: result.success,
        json: result.json["hydra:member"].map(lesson => transformLesson(lesson))
    };

    // console.log("getLessons", result, "-->", transformed);

    return transformed;
}


/*
    Returns:
    {
        success: boolean,
        json: transformLesson()
    }
*/
export async function getLesson(id) {
    const result = await request(null, __BACKEND__.api + `contents?id=${id}`);

    // console.log("getLesson", result );

    const lesson = result.json["hydra:member"][0];

    const transformed = {
        success: result.success,
        json: transformLesson(lesson)
    };

    // console.log("getLesson", transformed);

    return transformed;
}


/*
    Returns (example):
    {
        "afbeelding": {
            "filename": "",
            "path": "",
            "media": "",
            "thumbnail": "",
            "fieldname": "afbeelding",
            "alt": "",
            "url": "",
            "extension": ""
        },
        "popup_afbeelding": {
            "filename": "",
            "path": "",
            "media": "",
            "thumbnail": "",
            "fieldname": "popup_afbeelding",
            "alt": "",
            "url": "",
            "extension": ""
        },
        "inhoud": "",
        "popup_tekst": "",
        "aandacht": false,
        "code": "E14.les1.Probleem.1",
        "titel": "Een etentje in de klas",
        "tekst": "Probleem schets",
        "url": "",
        "popup_video": "",
        "niveau": "onderbouw",
        "soort": "Tekst",
        "slug": "126",
        "afbeeldingen": [
            {
                "afbeelding": {
                    "url": "",
                    ...
                },
                "ondertitel": "",
                "toelichting": "",
            },
            ...
        ]
    }
*/
function transformSlide(slide) {
    let result = { ...slide.fieldValues };
    if (result.afbeeldingen) {
        result.afbeeldingen = result.afbeeldingen.map(el => el.value);
    }
    if (result.popups) {
        // console.log("POPUPS:", result.popups);
        result.popups = result.popups.map(el => {
            let result = {
                type: el.name,
                label: el.value.label,
                weergave: el.value.weergave,
            }
            switch (el.name) {
                case 'afbeelding':
                    result.value = el.value.afbeelding;
                    break;
                case 'woorden':
                    result.value = el.value.woorden.split(/[\r\n]+/);
                    break;
                case 'video':
                    result.value = el.value.video;
                    break;
                case 'tekst':
                case 'pasop':
                    result.value = el.value.tekst;
                    break;
            }
            return result;
        });
        // console.log("POPUPS TRANSFORMED:", result.popups);
    }
    return result;
}


/*
    Returns:
    {
        success: boolean,
        json: transformSlide()
    }
*/
export async function getSlide(id) {
    const result = await request(null, `${__BACKEND__.api}contents?id=${id}`);

    // console.log("getSlide", result );

    const slide = result.json["hydra:member"][0];

    const transformed = {
        success: result.success,
        json: transformSlide(slide)
    };

    // console.log("getSlide", transformed);

    return transformed;
}

export const SlideSoort = {
    Tekst: 'Tekst',
    Woordweb: 'Woordweb',
    AfbeeldingTekst: 'Afbeelding met Tekst',
    Afbeeldingen: 'Afbeeldingen',
    LijstInvoer: 'Lijst Invoer',
    LijstWeergave: 'Lijst Weergave',
    Video: 'Video',
    Interactive: 'Interactive',
    Embed: 'Embed',
    VideoFile: 'VideoFile'
}