import React from 'react';
import BoltImage from '../../bolt/BoltImage';
import './TekstAfbeeldingSlideContent.scss';

const TekstAfbeeldingSlideContent = ({ slide }) => {
    return <div className="tekst-afbeelding-slide">
        <div dangerouslySetInnerHTML={{ __html: slide.inhoud }}></div>
        <div>
            <BoltImage info={slide.afbeelding} />
        </div>
    </div>
}
export default TekstAfbeeldingSlideContent;