import React from 'react';
import { CONFIRM_LEAVE_PAGE } from '../../../services/constants';

function download(filename, text) {
    // credit : https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
    
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function openFileDialog(accept, callback) {
    // credit: https://stackoverflow.com/questions/6463439/how-to-open-a-file-browse-dialog-using-javascript

    // this function must be called from  a user
    // activation event (ie an onclick event)

    // Create an input element
    var inputElement = document.createElement("input");

    // Set its type to file
    inputElement.type = "file";

    // Set accept to the file types you want the user to select. 
    // Include both the file extension and the mime type
    inputElement.accept = accept;

    // set onchange event to call callback when user has selected file
    inputElement.addEventListener("change", callback)

    // dispatch a click event to open the file dialog
    inputElement.dispatchEvent(new MouseEvent("click"));
}

const SlideExportImport = ({ slideField }) => {

    function importClicked(e) {
        if( !slideField.isChanged || window.confirm(CONFIRM_LEAVE_PAGE) ) {
            openFileDialog(".txt,text/plain", fileDialogChanged);
        }
    }

    function exportClicked(e) {
        const data = slideField.onExport();
        console.log('exporting', data, slideField);
        const s = data.join('\n');
        const filename = `MAAK ${slideField.json.code} ${slideField.json.soort} ${slideField.json.tekst}.txt`;
        download(filename, s);
        slideField.setIsChanged(false);
    }

    async function fileDialogChanged (e) {
        if( this.files && this.files.length > 0 ) {
            const file = this.files[0];
            const text = await file.text();            
            console.log(file, text);            
            slideField.onImport(text.split('\n'));
        }
    }

    // console.log('[SlideExportImport]', slideField);
    return slideField.hasData ? <>
        <button onClick={exportClicked}>Opslaan{slideField.isChanged ? <span>*</span> : null }</button>
        <button onClick={importClicked}>Openen</button>
    </> : null;
}
export default SlideExportImport;
