import React from 'react';
import BoltVideo from '../../bolt/BoltVideo';
import './VideoSlideContent.scss';

const VideoSlideContent = ({ slide }) => {
    return <div className="video-slide">
        <BoltVideo info={slide.video} />
        <p>{slide.tekst}</p>
    </div>
}
export default VideoSlideContent;