import React, { useEffect, useState } from "react";

const AsyncContent = ({ promise, render }) => {
    const [json, setJson] = useState();
    const [failed, setFailed] = useState(false);
    useEffect(() => {
        promise.then(({ success, json }) => {
            if (success) {
                setJson(json);
            } else {
                setFailed(true);
            }
        }, (reason) => {
            setFailed(reason);
        });
        return false;
    });
    if (failed) {
        console.log("fail reason:", failed);
        return <p>FAILED: {String(failed)}</p>;
    }
    else if (json) return render(json);
    else return <p>LOADING</p>;
}

export default AsyncContent;