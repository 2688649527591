import React, { useRef } from 'react';
import BoltImage from '../bolt/BoltImage';
import BoltVideo from '../bolt/BoltVideo';

function hasValue(value) {
    return value !== null, value !== "" && typeof (value) !== "undefined";
}

function getPopupContent(popup) {
    // console.log('getPopupContent', popup);
    switch (popup.type) {
        case 'afbeelding':
            return <div className="popup-afbeelding">
                <BoltImage info={popup.value} />
            </div>;
        case 'woorden':
            return <div className="popup-woorden">
                {popup.value.map((el, index) => <p key={index}>{el}</p>)}
            </div>;
        case 'tekst':
        case 'pasop':
            return <div className="popup-tekst" dangerouslySetInnerHTML={{ __html: popup.value }}></div>;
        case 'video':
            return <div className="popup-video">
                <BoltVideo info={popup.value} />
            </div>;
    }
}

const PopupButton = ({ popup, onTooltip, onPopup }) => {
    const label = popup.type === 'pasop' ? 'Pas op!' : popup.label;
    const cls = popup.type === 'pasop' ? 'button left-icon warning' : null;
    const elementRef = useRef();
    const fill = popup.type === 'video' || popup.weergave !== 'kleine tooltip';
    return <button ref={elementRef} onClick={() =>
        fill
            ? onPopup(getPopupContent(popup))
            : onTooltip(getPopupContent(popup), elementRef)
    } className={cls}>{label}</button>;
}

const PopupButtons = ({ slide, onTooltip, onPopup }) => {
    if (!hasValue(slide.popups)) return null;

    return slide.popups.map((popup, index) => <PopupButton key={index} popup={popup} onTooltip={onTooltip} onPopup={onPopup} />);
}
export default PopupButtons;