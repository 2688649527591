import React from 'react';
// import ChapterIcon from './ChapterIcon.js';

import './ChapterThumb.scss';

const ChapterThumb = ({ chapter, index, selected }) => {
    return <a className={selected ? "chapter-thumb selected" : "chapter-thumb"} href={`#${index}/0`}>
        <div>{chapter.nummering}</div>
        <p>
            {chapter.titel}
        </p>
    </a>
}
export default ChapterThumb;